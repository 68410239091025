import { useContext, useEffect, useState } from "react";
import { secondaryColor, token } from "../constants/constants";
import { LoginContext } from "../Contexts/loginContext";
import { fetchUserInfo } from "../api/UserInfo";
import {
  InputField,
  MainButton,
  SingleStepVerification,
} from "./../components";
import { firstStepKyc, secondStepKycApi, thirdStepKycApi } from "../api/kyc";
import CancelButton from "./CancelButton";
import { useNavigate } from "react-router-dom";
import VirtualCardShimmer from "./VirtualCardShimmer";
import countries from "./../assets/countryStateCity.json";

const VerifyKyc = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const { user, setUser } = useContext(LoginContext);
  const [firstName, setFirstName] = useState(user.first_name || "");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState(user.last_name || "");
  const [lastNameEror, setLastNameError] = useState("");
  const [email, setEmail] = useState(user.email || "");
  const [emailError, setEmailError] = useState("");
  const [phone, setPhone] = useState(user.phone || "");
  const [phoneError, setPhoneError] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [birthDateError, setBirthDateError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [states, setStates] = useState();
  const [stateError, setStateError] = useState("");
  const [cities, setCities] = useState();
  const [cityError, setCityError] = useState("");
  const [addressLine, setAddressLine] = useState("");
  const [addressLineError, setAddressLineError] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [idNumberError, setIdNumberError] = useState("");
  const [file, setFile] = useState("");
  const [fileError, setFileError] = useState("");
  const [documentTypeError, setDocumentTypeError] = useState("");
  const [selectedDocumentType, setSelectedDocumentType] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [stateDropDownOpen, setStateDropDownOpen] = useState(false);
  const [cityDropDownOpen, setCityDropDownOpen] = useState(false);
  const [step, setStep] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [stateSearchQuery, setStateSearchQuery] = useState("");
  const [selectedSate, setSelectedState] = useState("");
  const [citySearchQuery, setCitySearchQuery] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const navigate = useNavigate();
  const getUserI = async () => {
    const info = await fetchUserInfo();
    setUser(info.data);
    setFirstName(info.data.first_name);
    setLastName(info.data.last_name);
    setEmail(info.data.email);
    setPhone(info.data.phone);
    setStep(info.data.step);
  };

  useEffect(() => {
    getUserI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateField = (field, errorSetter, errorMessage) => {
    if (!field) {
      errorSetter(errorMessage);
      return false;
    }
    return true;
  };

  function onFirstStepSubmit() {
    setIsLoading(true);
    const validateEmail = () => {
      if (!email) {
        setIsLoading(false);
        setEmailError("Your email is required");
        return false;
      }
      if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
        // if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
        setIsLoading(false);

        setEmailError("Please enter a valid email");
        return false;
      }
      setIsLoading(false);
      return true;
    };

    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setPhoneError("");
    setBirthDateError("");
    setFileError("");
    setIdNumberError("");

    if (
      !validateField(firstName, setFirstNameError, "First name is required") ||
      !validateField(lastName, setLastNameError, "Last Name is required") ||
      !validateEmail() ||
      !validateField(phone, setPhoneError, "Phone is required") ||
      !validateField(birthDate, setBirthDateError, "Date of birth is required")
    ) {
      return;
    }
    verifyKyc();
  }

  const secondStepSubmit = () => {
    setCountryError("");
    setStateError("");
    setCityError("");
    setAddressLineError("");
    setPostalCodeError("");
    if (
      !validateField(
        selectedCountry,
        setCountryError,
        "The country field is required"
      ) ||
      !validateField(
        selectedSate,
        setStateError,
        "The state field is required"
      ) ||
      !validateField(
        selectedCity,
        setCityError,
        "The city field is required!"
      ) ||
      !validateField(
        addressLine,
        setAddressLineError,
        "The address line field is required"
      ) ||
      !validateField(
        postalCode,
        setPostalCodeError,
        "The postal code field is required"
      )
    ) {
      return;
    }
    verifyKyc();
  };

  const thirdStepSubmit = () => {
    if (
      !validateField(
        selectedDocumentType,
        setDocumentTypeError,
        "The document type field is required"
      ) ||
      !validateField(
        file,
        setFileError,
        "The Passport / verified id field is required"
      ) ||
      !validateField(
        idNumber,
        setIdNumberError,
        "The id number field is required"
      )
    ) {
      return;
    }

    verifyKyc();
  };

  const verifyKyc = async () => {
    setIsLoading(true);
    if (step === 0) {
      await firstStepKyc(birthDate);
      setIsLoading(false);
    }
    if (step === 1) {
      await secondStepKycApi(
        addressLine,
        selectedCity.name,
        selectedSate.name,
        selectedCountry.name,
        postalCode
      );
      setIsLoading(false);
    }
    if (step === 2) {
      if (file) {
        let kycReponse = await thirdStepKycApi(
          selectedDocumentType,
          file,
          idNumber
        );
        if (kycReponse.status === true) {
          navigate("/virtual-cards", { replace: true });
        }
        setIsLoading(false);
      }
    }
    getUserI();
  };

  const filteredCountries = countries.filter((country) => {
    return country.name.toLowerCase().includes(searchQuery.toLowerCase());
  });
  const filteredStates =
    states &&
    states.filter((state) =>
      state.name.toLowerCase().includes(stateSearchQuery.toLowerCase())
    );
  const filteredCities =
    cities &&
    cities.filter((city) =>
      city.name.toLowerCase().includes(citySearchQuery.toLowerCase())
    );
  const handleSelectChange = (country) => {
    setSelectedCountry(country);
    setSearchQuery("");
    setStateSearchQuery("");
    setStates(country.states);
    setSelectedState("");
    setSelectedCity("");

    setDropdownOpen(false);
  };
  const handleStateSelectChange = (state) => {
    setSelectedState(state);
    setCities(state.cities);
    setSelectedCity("");
    setStateDropDownOpen(false);
  };
  const handleCitySelectChange = (city) => {
    setSelectedCity(city);
    setCityDropDownOpen(false);
  };

  const onCancelClick = () => {
    setIsCancelLoading(true);
    navigate("/virtual-cards");
  };
  return (
    <div className="w-full flex-col items-center justify-center">
      {token ? (
        <div className={`h-screen`}>
          <div className="w-full flex flex-col items-center justify-center mt-32 gap-6">
            <h1
              style={{ color: secondaryColor }}
              className={`text-4xl font-bold text-[${secondaryColor}] text-center flex flex-col items-center gap-2 justify-center py-3`}
            >
              Verify Your Account
              <hr className="w-full md:w-[30%]" />
            </h1>
            <div className="w-[90%] max-w-[1000px] flex gap-2 items-center justify-between">
              <SingleStepVerification step={"01"} isActive={true} />
              <div
                className={`h-2 flex-grow ${
                  step >= 1 ? "bg-[#1f7e95] " : "bg-slate-400 "
                } rounded-md`}
              ></div>
              <SingleStepVerification
                step={"02"}
                isActive={step >= 1 ? true : false}
              />
              <div
                className={`h-2 flex-grow ${
                  step >= 2 ? "bg-[#1f7e95] " : "bg-slate-400 "
                } rounded-md`}
              ></div>
              <SingleStepVerification
                step={"03"}
                isActive={step >= 2 ? true : false}
              />
            </div>
            <div className="w-[90%] max-w-[1000px] bg-white rounded-lg border shadow-md  px-2 sm:px-10 py-10">
              <h1
                style={{ color: secondaryColor }}
                className={`text-md font-bold text-[${secondaryColor}] text-center flex flex-col items-center gap-2 justify-center py-2`}
              >
                {step === 0
                  ? "Personal Information "
                  : step === 1
                  ? "Address "
                  : "Document "}
                verification
                <hr className="w-full md:w-[30%]" />
              </h1>

              <div className="py-10 flex justify-center space-y-3 text-lg text-slate-600 ">
                <div className="flex justify-center flex-col items-center w-full ">
                  {step === 0 ? (
                    <>
                      <div className="flex flex-col md:flex-row gap-4 w-full space-y-3 md:space-y-0 justify-center items-start">
                        <InputField
                          disabled={true}
                          label={"First Name"}
                          placeholder={"First Name"}
                          type={"text"}
                          value={firstName}
                          onchange={(ev) => setFirstName(ev.target.value)}
                          error={firstNameError}
                        />
                        <InputField
                          disabled={true}
                          label={"Last Name"}
                          placeholder={"Last Name"}
                          type={"text"}
                          value={lastName}
                          onchange={(ev) => setLastName(ev.target.value)}
                          error={lastNameEror}
                        />
                      </div>
                      <br />
                      <div className="flex flex-col md:flex-row gap-4 w-full space-y-3 md:space-y-0 justify-center items-start">
                        <InputField
                          disabled={true}
                          label={"Email"}
                          placeholder={"Email"}
                          type={"text"}
                          value={email}
                          onchange={(ev) => setEmail(ev.target.value)}
                          error={emailError}
                        />
                        <InputField
                          disabled={true}
                          label={"Phone"}
                          placeholder={"Phone"}
                          type={"text"}
                          value={phone}
                          onchange={(ev) => setPhone(ev.target.value)}
                          error={phoneError}
                        />
                      </div>
                      <br />
                      <div className="flex flex-col md:flex-row gap-4 w-full space-y-3 md:space-y-0 justify-center items-start">
                        <InputField
                          label={"Date of birth"}
                          placeholder={"Date of birth"}
                          type={"date"}
                          value={birthDate}
                          onchange={(ev) => setBirthDate(ev.target.value)}
                          error={birthDateError}
                        />
                      </div>
                    </>
                  ) : step === 1 ? (
                    <>
                      <div className="flex flex-col md:flex-row gap-4 w-full space-y-3 md:space-y-0 justify-center items-start">
                        <div className="relative inline-block w-full">
                          <label>
                            Country <span className="text-red-500">*</span>
                          </label>
                          <button
                            onClick={() => {
                              setDropdownOpen(!dropdownOpen);
                              setStateDropDownOpen(false);
                              setCityDropDownOpen(false);
                            }}
                            className="w-full border py-3 px-2 rounded-md focus:outline-none flex"
                          >
                            {selectedCountry === "" ? (
                              <span className="text-slate-500 text-md">
                                --Select Country--
                              </span>
                            ) : (
                              selectedCountry.name
                            )}
                          </button>
                          {dropdownOpen && (
                            <div className="absolute z-10 w-full max-h-300px overflow-y-auto mt-1 bg-white border rounded-md shadow-lg">
                              <input
                                type="text"
                                placeholder="Search country"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="   w-full border py-2 px-3 rounded-md mb-2"
                              />
                              <div className="max-h-[300px] overflow-y-auto p-2">
                                {filteredCountries.map((val) => (
                                  <div
                                    key={val.name}
                                    onClick={() => handleSelectChange(val)}
                                    className="cursor-pointer py-2 px-4 hover:bg-gray-200 "
                                  >
                                    <div className="flex gap-2">
                                      <label>{val.emoji}</label>
                                      {val.name}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                          <label className="text-red-500 text-sm">
                            {countryError}
                          </label>
                        </div>

                        <div className="relative inline-block w-full">
                          <label>
                            State <span className="text-red-500">*</span>
                          </label>
                          <button
                            onClick={() => {
                              setStateDropDownOpen(!stateDropDownOpen);
                              setDropdownOpen(false);
                              setCityDropDownOpen(false);
                            }}
                            className="w-full border py-3 px-2 rounded-md focus:outline-none flex"
                          >
                            {selectedSate === ""
                              ? "--Select State--"
                              : selectedSate.name}
                          </button>
                          {stateDropDownOpen && (
                            <div className="absolute z-10 w-full max-h-300px overflow-y-auto mt-1 bg-white border rounded-md shadow-lg">
                              {states && (
                                <>
                                  <input
                                    type="text"
                                    placeholder="Search country"
                                    value={stateSearchQuery}
                                    onChange={(e) =>
                                      setStateSearchQuery(e.target.value)
                                    }
                                    className="w-full border py-2 px-3 rounded-md mb-2"
                                  />
                                  <div className="max-h-[300px] overflow-y-auto p-2">
                                    {filteredStates.map((val) => (
                                      <div
                                        key={val.name}
                                        onClick={() =>
                                          handleStateSelectChange(val)
                                        }
                                        className="cursor-pointer py-2 px-4 hover:bg-gray-200"
                                      >
                                        {val.emoji}
                                        {val.name}
                                      </div>
                                    ))}
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                          <label className="text-red-500 text-sm">
                            {stateError}
                          </label>
                        </div>
                      </div>
                      <br />
                      <div className="flex flex-col md:flex-row gap-4 w-full space-y-3 md:space-y-0 justify-center items-start">
                        <div className="relative inline-block w-full">
                          <label>
                            City <span className="text-red-500">*</span>
                          </label>
                          <button
                            onClick={() => {
                              setCityDropDownOpen(!cityDropDownOpen);
                              setStateDropDownOpen(false);
                              setDropdownOpen(false);
                            }}
                            className="w-full border py-3 px-2 rounded-md focus:outline-none flex"
                          >
                            {selectedCity === ""
                              ? "--Select City--"
                              : selectedCity.name}
                          </button>
                          {cityDropDownOpen && (
                            <div className="absolute z-10 w-full max-h-300px overflow-y-auto mt-1 bg-white border rounded-md shadow-lg">
                              {cities && (
                                <>
                                  <input
                                    type="text"
                                    placeholder="Search country"
                                    value={citySearchQuery}
                                    onChange={(e) =>
                                      setCitySearchQuery(e.target.value)
                                    }
                                    className="w-full border py-2 px-3 rounded-md mb-2"
                                  />
                                  <div className="max-h-[300px] overflow-y-auto p-2">
                                    {filteredCities.map((val) => (
                                      <div
                                        key={val.name}
                                        onClick={() =>
                                          handleCitySelectChange(val)
                                        }
                                        className="cursor-pointer py-2 px-4 hover:bg-gray-200"
                                      >
                                        {val.emoji}
                                        {val.name}
                                      </div>
                                    ))}
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                          <label className="text-red-500 text-sm">
                            {cityError}
                          </label>
                        </div>
                        {/* <InputField
                          label={"City"}
                          placeholder={"City"}
                          type={"text"}
                          value={city}
                          onchange={(ev) => setCity(ev.target.value)}
                          error={cityError}
                        /> */}
                        <InputField
                          label={"Address Line"}
                          placeholder={"Address Line"}
                          type={"text"}
                          value={addressLine}
                          onchange={(ev) => setAddressLine(ev.target.value)}
                          error={addressLineError}
                        />
                      </div>
                      <br />
                      <div className="flex flex-col md:flex-row gap-4 w-full space-y-3 md:space-y-0 justify-center items-start">
                        <InputField
                          label={"Postal Code"}
                          placeholder={"Postal Code"}
                          type={"text"}
                          value={postalCode}
                          onchange={(ev) => setPostalCode(ev.target.value)}
                          error={postalCodeError}
                        />
                      </div>
                    </>
                  ) : step === 2 ? (
                    <>
                      <div className="flex flex-col md:flex-row gap-4 w-full space-y-3 md:space-y-0 justify-center items-start">
                        {/* <InputField
                        label={"Passport / Verified Id"}
                        placeholder={"Verified Id/Passport"}
                        type={"file"}
                        value={file}
                        onchange={(ev) => setFile(ev.target.value)}
                        error={fileError}
                      /> */}
                        <div className="flex flex-col w-full gap-1 ">
                          <label>
                            Document Type
                            <span className="text-red-500">*</span>
                          </label>
                          <select
                            value={selectedDocumentType}
                            onChange={(e) => {
                              setSelectedDocumentType(e.target.value);
                            }}
                            name="input"
                            id=""
                            className="w-full border py-2.5 px-3 rounded-md border-slate-600/50 "
                          >
                            <option>--Select Document Type--</option>
                            <option value="passport">Passport</option>
                            <option value="nationalId">National Id</option>
                            {/* <option value="drivingLicense">
                              Driving Liscence
                            </option>
                            <option value="kebeleId">Kebele Id</option> */}

                            {/* )} */}
                          </select>
                          <label className="text-red-500 text-sm">
                            {documentTypeError}
                          </label>
                        </div>
                        <InputField
                          label={"Id Number"}
                          placeholder={"Id Number"}
                          type={"text"}
                          value={idNumber}
                          onchange={(ev) => setIdNumber(ev.target.value)}
                          error={idNumberError}
                        />
                      </div>
                      <div className="flex flex-col md:flex-row gap-4 w-full space-y-3 md:space-y-0 justify-center items-start">
                        <InputField
                          label={"Passport / Verified Id"}
                          placeholder={"Verified Id/Passport"}
                          type={"file"}
                          // value={file}
                          onchange={(ev) => setFile(ev.target.files[0])}
                          error={fileError}
                        />
                        {/* <InputField
                        label={"Id Number"}
                        placeholder={"Id Number"}
                        type={"text"}
                        value={idNumber}
                        onchange={(ev) => setIdNumber(ev.target.value)}
                        error={idNumberError}
                      /> */}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="w-full">
                        <VirtualCardShimmer className={"w-full h-[300px]"} />
                      </div>
                    </>
                  )}

                  {/* <br /> */}

                  <br />
                  <br />
                  <div className="w-full flex gap-5 ">
                    <CancelButton
                      label={"Cancel"}
                      onClick={onCancelClick}
                      isLoading={isCancelLoading}
                    />
                    <MainButton
                      label={step >= 2 ? "Verify" : "Next"}
                      onClick={() => {
                        step === 0
                          ? onFirstStepSubmit()
                          : step === 1
                          ? secondStepSubmit()
                          : step === 2
                          ? thirdStepSubmit()
                          : console.log("error");
                      }}
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        navigate("/login")
      )}
    </div>
  );
};
export default VerifyKyc;
