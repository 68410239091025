import { useNavigate } from "react-router-dom";
import {
  AmazonLogo,
  ChatGpt,
  Discord,
  GooglePlayLogo,
  LandingImage,
  TelegramLogo,
  UdemyLogo,
  facebook,
  logo,
  netflixLog,
  spotifyLogo,
} from "../assets";
import { primaryColor } from "../constants/constants";
import OnlineServices from "./onlineServices";

const FormSideUi = () => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className={`w-full h-full min-h-screen bg-[#58d1f030]/10 lg:flex flex-col justify-between hidden `}
      >
        <div
          className="flex  sm:px-10 pt-10 items-center sm:cursor-pointer"
          onClick={() => {
            navigate("/");
          }}
        >
          <img className="h-20 md:h-20 " src={logo} alt="" />
          <div
            className={`text-xl md:text-2xl font-bold text-[${primaryColor}]`}
          >
            {/* YEHADIGITAL */}
          </div>
        </div>
        <div className="w-full h-full flex flex-col items-center justify-center p-10 gap-8">
          <div className="flex flex-col w-full">
            <div className="text-2xl text-slate-700 text-left w-full px-2">
              Go global with our Virtual Debit Card!
            </div>
            <div className="text-3xl text-slate-700 text-left w-full px-2 font-medium">
              Instant and secure international payments.
            </div>
          </div>
          <div className="relative h-full flex items-center justify-center -rotate-6">
            <img
              src={LandingImage}
              alt=""
              className=" rotate-12 max-w-[450px] transition-transform duration-300 ease-in-out hover:rotate-6"
            />
          </div>
        </div>
        <div className="flex flex-col h-full  justify-end gap-3 py-20">
          <div className="flex w-full justify-center gap-3 lg:tex-sm xl:text-md">
            <OnlineServices image={spotifyLogo} title={"Spotify"} />
            <OnlineServices image={TelegramLogo} title={"Telegram"} />
            <div className="hidden 2xl:flex">
              <OnlineServices image={ChatGpt} title={"Chat GPT"} />
            </div>
            <OnlineServices image={facebook} title={"Facebook"} />
          </div>
          <div className="flex w-full justify-center gap-3">
            <OnlineServices image={netflixLog} title={"Netflix"} />

            <OnlineServices image={AmazonLogo} title={"Amazon"} />
            <OnlineServices image={Discord} title={"Discord"} />
            <div className="hidden xl:flex">
              <OnlineServices image={GooglePlayLogo} title={"Google Play"} />
            </div>
            <div className="hidden lg:flex xl:hidden  2xl:flex">
              <OnlineServices image={UdemyLogo} title={"Udemy"} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FormSideUi;
