const primaryColor = "#2cb4d5";
const secondaryColor = "#1f7e95";
const primaryBgColor = "#ecf7fa";
const baseUrl = process.env.REACT_APP_BASE_URL;
const testBaseUrl = "https://yehamukera.yehadigital.com/api/";
const token = localStorage.getItem("token");

export {
  primaryColor,
  secondaryColor,
  primaryBgColor,
  baseUrl,
  testBaseUrl,
  token,
};
