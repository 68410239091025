import { ContactDetail, Email, Telegram, Location } from "../components";
import { primaryBgColor, primaryColor } from "../constants/constants";

const ContactUs = () => {
  const svgStyle = {
    height: "1.5rem",
    width: "1.5rem",
    color: primaryColor,
  };
  return (
    <div className="w-full flex-col items-center justify-center">
      <div className={`min-h-[70vh] `}>
        <div
          style={{ backgroundColor: primaryBgColor }}
          className={` min-h-[50vh] top-0 w-full flex items-center justify-center bg-[${primaryBgColor}]`}
        >
          <div className="flex flex-col items-center space-y-4">
            <div className={`text-[${primaryColor}] text-5xl font-bold `}>
              Contact Us
            </div>
            <div className="w-[90%] sm:w-[50%] text-center text-lg text-slate-700">
              Have questions or ready to kickstart your digital journey? Reach
              out to YEHADIGITAL! We're here to assist you. let's turn your
              ideas into digital reality together.
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center">
          <div className="w-[90%] md:w-[60%] bg-white rounded-md border shadow-md -mt-32 p-10">
            <div className="flex flex-col space-y-4 justify-center">
              {/* <ContactDetail
                title="PHONE"
                content="+251 96 475 6735"
                icon={<PhoneIcon style={svgStyle} />}
              /> */}
              <ContactDetail
                title="TELEGRAM"
                content={
                  <a
                    href="https://t.me/yehacard"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500"
                  >
                    @yehacard
                  </a>
                }
                icon={<Telegram style={svgStyle} />}
              />
              <ContactDetail
                title="EMAIL"
                content="hello@yehadigital.com"
                icon={<Email style={svgStyle} />}
              />
              <ContactDetail
                title="ADDRESS"
                content="1 26/28 BEDFORD ROW"
                icon={<Location style={svgStyle} />}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
