import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "../components";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="w-full flex flex-col items-center text-sm">
        <div className="w-[90%] sm:w-[70%] text-slate-600 space-y-4">
          <div className="w-full py-4 text-2xl text-slate-600 font-bold flex items-center space-x-2">
            <div
              onClick={() => {
                navigate(-1);
              }}
              className="sm:cursor-pointer"
            >
              <ArrowLeft className={"h-6 w-6"} />
            </div>

            <div className="">Privacy Policy</div>
          </div>
          <hr />

          <div className="text-xl font-bold text-slate-950  py-2">
            <h1> INTRODUCTION</h1>
          </div>
          <div className="text-left  space-y-5 py-2">
            <p>
              This Policy describes how YEHADIGITAL on behalf of itself will
              ensure the data privacy of its users.
            </p>
            <p>
              This Policy applies to the personal data collected by YEHADIGITAL
              when you use our website, mobile application, social media
              platforms, tools and any other platform belonging to YEHADIGITAL
              (collectively, the "Services" ) or otherwise interact with us as
              described below.
            </p>
            <p>
              We may update our Policy from time to time at our sole discretion.
              Thus, you are advised to review this page periodically for any
              changes. These changes are effective immediately after they are
              posted on this page. Where updates are made to the Policy, we will
              notify you and in some cases, provide you with additional notice (
              by adding a statement to the homepages of our website or mobile
              application; or by sending you an email notification).
            </p>
          </div>
          <div className="text-xl font-bold text-slate-950  py-2">
            <h1>CONSENT</h1>
          </div>
          <div>
            <ul className="space-y-3 ">
              <li>
                <p>
                  You accept this Policy when you access our platforms, or use
                  our Services, technologies, content or functions offered on
                  our website, mobile applications, digital platforms, social
                  media, amongst other platforms which may be contemplated to be
                  used by YEHADIGITAL.
                </p>
              </li>
              <li>
                <p>
                  By signing up to our platforms and using our Services, you
                  hereby confirm that you have read, understand, and agree to be
                  bound by this Policy. If you do not wish to be bound by the
                  provisions of this Policy, you should not access the platforms
                  or sign up for our Services.
                </p>
              </li>
              <li>
                <p>
                  If you choose to use our Services, then you agree to the
                  collection and use of information in relation to this Policy.
                  The personal data that we collect is used for providing and
                  improving our Services. We will not use or share your
                  information with anyone except as described in this Policy.
                </p>
              </li>
            </ul>
          </div>
          <div className="font-bold text-xl text-slate-950 py-2">
            COLLECTION OF PERSONAL DATA
          </div>
          <div className="">
            <ul className="space-y-3 text-md">
              <li>
                For a better experience, while using our Services, we may
                require you to provide us with certain personally identifiable
                information, including but not limited to your name, address,
                phone number, email address, date of birth, username, password.
              </li>
              <li>
                When you create a YEHADIGITAL account, we may collect
                information about you from third party service providers to
                verify your identity and for fraud prevention. We will notify
                you when we receive information about you from them and the
                purposes for which we intend to use that information. The
                information that we request will be retained by us and used as
                described in this Policy. The platform does use third party
                services that may collect information used to identify you.
              </li>
            </ul>
          </div>
          <div className="font-bold text-xl text-slate-950 py-2">
            Personal Data
          </div>
          <div className="">
            <p>
              Personal data means any data which relates to a living individual
              who can be identified from that data, or from that data and other
              information which is in the possession of, or is likely to come
              into the possession of, YEHADIGITAL (or its representatives or
              service providers). In addition to factual information, it
              includes any expression of opinion about an individual and any
              indication of the intentions of YEHADIGITAL or any other person in
              respect of an individual. The definition of personal data depends
              on the relevant law applicable to your physical location.
            </p>
          </div>
          <div className="font-bold text-xl text-slate-950 py-2">
            Usage Data
          </div>
          <div className=" space-y-5">
            <p>Usage Data is collected automatically when using the Service.</p>
            <p>
              Usage Data may include information such as your device’s Internet
              Protocol address (e.g. IP address), browser type, browser version,
              the pages of our Service that you visit, the time and date of your
              visit, the time spent on those pages, unique device identifiers
              and other diagnostic data.
            </p>
            <p>
              When you access the Service by or through a mobile device, we may
              collect certain information automatically, including, but not
              limited to, the type of mobile device you use, your mobile device
              unique ID, the IP address of your mobile device, your mobile
              operating system, the type of mobile Internet browser you use,
              unique device identifiers and other diagnostic data.
            </p>
            <p>
              We may also collect information that your browser sends whenever
              you visit our Service or when you access the Service by or through
              a mobile device.
            </p>
          </div>
          <div className="font-bold text-xl text-slate-950 py-2">
            Data from Third-Parties
          </div>
          <div className=" space-y-5">
            <p>
              This includes information YEHADIGITAL may obtain about you from
              third party sources. The main types of third parties we receive
              your personal data from are:
            </p>
            <p>
              Public Databases, Credit Bureaus & ID Verification Partners in
              order to verify your identity in accordance with applicable law.
              ID verification partners such as BASIS ID use a combination of
              government records and publicly available information about you to
              verify your identity. Such information may include your name,
              address, job role, public employment profile, credit history,
              status on any sanction’s lists maintained by public authorities,
              and other relevant data.
            </p>
            <p>
              Marketing Partners & Resellers so that we can better understand
              which of our Services may be of interest to you.
            </p>
          </div>
          <div className="font-bold text-xl text-slate-950 py-2">
            Tracking Technologies and Cookies
          </div>
          <div className="space-y-5">
            <p>
              YEHADIGITAL use Cookies and similar tracking technologies to track
              the activity on our Service and store certain information.
              Tracking technologies used are beacons, tags, and scripts to
              collect and track information and to improve and analyse our
              Service. The technologies we use may include:
            </p>
            <p>
              Cookies or Browser Cookies. A cookie is a small file placed on
              your sevice. you can instruct your browser to refuse all Cookies
              or to indicate when a Cookie is being sent. However, if you do not
              accept Cookies, you may not be able to use some parts of our
              Service. Unless you have adjusted your browser setting so that it
              will refuse Cookies, our Service may use Cookies.
            </p>
          </div>
          <div className="font-bold text-xl text-slate-950 py-2">
            Use of Your Personal Data
          </div>
          <div className="space-y-5">
            <p>
              YEHADIGITAL may use your personal data in the following ways and
              for the following purposes:
            </p>
            <ul className="space-y-5">
              <li>
                To provide and maintain our Service, including to monitor the
                usage of our Service.
              </li>
              <li>
                To maintain legal and regulatory compliance, we need to process
                your personal data in order to comply with anti-monetary
                laundering and security laws. We also process your personal data
                in order to help detect, prevent, and mitigate fraud and abuse
                of our Services and to protect you against account compromise or
                funds loss.
              </li>
              <li>
                For the performance of a contract, the development, compliance
                and undertaking of the purchase contract for the products, items
                or services you have purchased or of any other contract with us
                through the Service.
              </li>
              <li>
                To contact you via email, telephone calls, SMS, or other
                equivalent forms of electronic communication, such as service
                updates regarding administrative or account-related information,
                security issues, or other transaction-related information. These
                communications are important to share developments that may
                affect how you can use our Services. You cannot opt-out of
                receiving critical service communications.
              </li>
              <li>
                To provide communications and customer services according to
                your preferences and in compliance with applicable law, we may
                send you marketing communications to inform you about events, to
                deliver targeted marketing and to share promotional offers. If
                you are a new client or partner, we will contact you by
                electronic means for marketing purposes only if you have
                consented to such communication. If you do not want us to send
                you marketing communications, please submit a request via email
                at hello@YEHADIGITAL.com
              </li>
              <li>
                We also process your personal information when you contact us to
                resolve any questions, disputes, or to troubleshoot problems.
                Without processing your personal information for such purposes,
                we cannot respond to your requests and ensure your uninterrupted
                use of the Services.
              </li>
              <li>
                For business transfers, we may use your data to evaluate or
                conduct a merger, divestiture, restructuring, reorganisation,
                dissolution, or other sale or transfer of some or all of our
                assets, whether as a going concern or as part of bankruptcy,
                liquidation, or similar proceeding, in which Personal Data held
                by us about our Service users is among the assets transferred.
              </li>
              <li>
                For other purposes: We may use your data for other purposes,
                such as data analysis, identifying usage trends, determining the
                effectiveness of our promotional campaigns, and evaluating and
                improving our Service, products, services, marketing and your
                experience.
              </li>
            </ul>
          </div>
          <div className="font-bold text-xl text-slate-950 py-2">
            Disclosing your Personal Data to Third Parties
          </div>
          <div className=" space-y-5">
            <p>
              YEHADIGITAL may share your personal data in the following
              situations:
            </p>
            <ul className="space-y-5">
              <li>
                With Service Providers: We may share your personal data with
                Service Providers to monitor and analyse the use of our Service,
                to contact you.
              </li>
              <li>
                With Affiliates: We may share your data with our affiliates, in
                which case we will require those affiliates to honor this
                Privacy Policy. Affiliates include our parent company and any
                other subsidiaries, joint venture partners or other companies
                that We control or that are under common control with us. Our
                contracts require these service providers to only use your data
                in connection with the services they perform for us and prohibit
                them from sharing your data with anyone else.
              </li>
              <li>
                With Business Partners: We may share your data with our business
                partners to offer you certain products, services or promotions.
                This includes Financial institutions which we partner with to
                process payments you have authorised.
              </li>
              <li>
                With other users: when you share personal data or otherwise
                interact in the public areas with other users, such information
                may be viewed by all users and may be publicly distributed
                outside. If you interact with other users, your contacts on the
                Third-Party Social Media Service may see your name, profile,
                pictures and description of your activity. Similarly, other
                users will be able to view descriptions of your activity,
                communicate with you and view your profile.
              </li>
              <li>
                Business Transfers: If we are involved in a merger, acquisition
                or asset sale, your personal data may be transferred. We will
                provide notice before your personal data is transferred and
                becomes subject to a different Privacy Policy
              </li>
              <li>
                Law enforcement: Under certain circumstances, we may be required
                to disclose your personal data if required to do so by law or in
                response to valid requests by public authorities (e.g. a court
                or a government agency)
              </li>
              <li>
                Other legal requirements: We may disclose your personal data in
                the good faith belief that such action is necessary to:
              </li>
              <li>Comply with a legal obligation</li>
              <li>Protect and defend our rights or property</li>
              <li>
                Prevent or investigate possible wrong doing in connection with
                the Service
              </li>
              <li>
                Protect the personal safety of Users of the Service or the
                public
              </li>
              <li>Protect against legal liability</li>
              <li>
                With your consent: We may disclose your personal data for any
                other purpose with your consent.
              </li>
            </ul>
          </div>
          <div className="font-bold text-xl text-slate-950 py-2">
            Transfer of Your Personal Data
          </div>
          <div className="space-y-5">
            <p>
              Your personal data is processed at Yehadigital’s operating offices
              and in any other places where the parties involved in the
              processing are located. It means that this information may be
              transferred to — and maintained on — computers located outside of
              your state, province, country or other governmental jurisdiction
              where the data protection laws may differ than those from your
              jurisdiction.
            </p>
            <p>
              Your consent to this Privacy Policy followed by your submission of
              such information represents your agreement to that transfer.
            </p>
            <p>
              YEHADIGITAL will take all steps reasonably necessary to ensure
              that your data is treated securely and in accordance with this
              Privacy Policy and no transfer of your personal data will take
              place to an organisation or a country unless there are adequate
              controls in place including the security of your data and other
              personal information.
            </p>
          </div>
          <div className="font-bold text-xl text-slate-950 py-2">
            Retention of Your Personal Data
          </div>
          <div className="space-y-5">
            <p>
              YEHADIGITAL will retain your personal data only for as long as is
              necessary for the purposes set out in this Privacy Policy. We will
              retain and use your personal data to the extent necessary to
              comply with our legal obligations (for example, if we are required
              to retain your data to comply with applicable laws), resolve
              disputes, and enforce our legal agreements and policies.
            </p>
            <p>
              We will also retain Usage Data for internal analysis purposes.
              Usage Data is generally retained for a shorter period of time,
              except when this data is used to strengthen the security or to
              improve the functionality of our Service, or we are legally
              obligated to retain this data for longer time periods.
            </p>
          </div>
          <div className="font-bold text-xl text-slate-950 py-2">
            Security of Your Personal Data
          </div>
          <div className="space-y-5">
            <p>
              The security of your personal data is important to us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage is 100% secure. While we strive to
              use commercially acceptable means to protect your personal data,
              we cannot guarantee its absolute security.
            </p>
            <p>
              YEHADIGITAL implement and maintains reasonable measures to protect
              our information and information systems. Customer files are
              protected with safeguards according to the sensitivity of the
              relevant information. Reasonable controls (such as restricted
              access) are placed on our computer systems. Physical access to
              areas where personal information is gathered, processed or stored
              is limited to authorised employees.
            </p>
            <p>
              We may store and process all or part of your personal data and
              transactional information, including certain payment information,
              such as your encrypted bank account and/or routing numbers,
              anywhere in the world where our facilities or service providers
              are located. We protect your personal information by maintaining
              physical, electronic, and procedural safeguards in compliance with
              the applicable laws and regulations.
            </p>
            <p>
              As a condition of employment, Yehadigital’s employees are required
              to follow all applicable laws and regulations, including in
              relation to data protection law. Access to sensitive personal
              information is limited to those employees who need to it to
              perform their roles. Unauthorised use or disclosure of
              confidential customer information by a YEHADIGITAL employee is
              prohibited and may result in disciplinary measures.
            </p>
            <p>
              When you contact an YEHADIGITAL employee about your file, you may
              be asked for some personal details. This type of safeguard is
              designed to ensure that only you, or someone authorised by you,
              has access to your file. You also play a vital role in protecting
              your own personal information; do not reveal personal data to any
              third-parties and immediately notify us if you become aware of any
              unauthorised access to or use of your account.
            </p>
          </div>
          <div className="font-bold text-xl text-slate-950 py-2">
            Children’s Personal Data
          </div>
          <div>
            <p>
              YEHADIGITAL does not knowingly request to collect personal data
              from any person under the age of 18. If a user submitting personal
              information is suspected of being younger than 18 years of age,
              YEHADIGITAL will require the user to close his or her account and
              will not allow the user to continue using our Services. We will
              also take steps to delete the data as soon as possible. Please
              notify us if you know of any individuals under the age of 18 using
              our Services so we can take action to prevent access to our
              Services.
            </p>
          </div>
          <div className="font-bold text-xl text-slate-950 py-2">
            Cross Border Transfers
          </div>
          <div>
            YEHADIGITAL is an international business with operations in regions
            and countries including Asia, the EU, the UK and the US. This means
            we may transfer to locations outside of your country. When we
            transfer your personal information to another country, we will
            ensure that any transfer of your personal information is compliant
            with applicable data protection law.
          </div>
          <div className="font-bold text-xl text-slate-950 py-2">
            Changes to this Privacy Policy
          </div>
          <div className="pb-12 space-y-5">
            <p>
              YEHADIGITAL may update our Privacy Policy from time to time. We
              will notify you of any changes by posting the new Privacy Policy
              on this page.
            </p>
            <p>
              We will let you know via email and/or a prominent notice on our
              Service, prior to the change becoming effective .
            </p>
            <p>
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default PrivacyPolicy;
