import { useState, useEffect } from "react";
import { logo } from "../assets";
import { baseUrl, secondaryColor } from "../constants/constants";
import { NavLink, useNavigate } from "react-router-dom";

const VerifyOtp = () => {
  const [otp, setOtp] = useState(["", "", "", "", "", "", "", ""]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(60);
  const [canResend, setCanResend] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const [otpStatus, setOtpStatus] = useState(null);
  const navigate = useNavigate();

  // Handle OTP input change and move focus automatically
  const handleOtpChange = (e, index) => {
    const value = e.target.value;

    if (/^[0-9]{0,1}$/.test(value)) {
      const updatedOtp = [...otp];
      updatedOtp[index] = value;
      setOtp(updatedOtp);

      if (value && index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  // Handle backspace or delete key press to jump and delete
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        const updatedOtp = [...otp];
        updatedOtp[index - 1] = "";
        setOtp(updatedOtp);
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
  };

  // Handle OTP verification
  const verifyOtp = () => {
    const otpCode = otp.join("");
    if (otpCode === "") {
      setError("The OTP field is required.");
      return;
    }

    setIsLoading(true);
    setError("");

    const email = localStorage.getItem("email");
    const requestPayload = JSON.stringify({ email, otp: otpCode });

    fetch(`${baseUrl}validate-otp`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: requestPayload,
    })
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);
        if (result.status) {
          localStorage.setItem("token", result.token);
          setOtpStatus("success");
          navigate("/virtual-cards");
        } else {
          setOtp(["", "", "", "", "", "", "", ""]);
          setError("");
          setOtpStatus("failure");
          setAttempts((prevAttempts) => prevAttempts + 1);

          if (attempts >= 3) {
            setError("Too many failed attempts.");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }

          document.getElementById("otp-input-0").focus();
        }
      })
      .catch(() => {
        setIsLoading(false);
        setError("An error occurred, please try again.");
      });
  };

  // Handle OTP resend
  const resendOtp = () => {
    setIsLoading(true);
    setError("");

    const email = localStorage.getItem("email");
    if (!email) {
      setIsLoading(false);
      setError("No email found. Please log in again.");
      return;
    }

    const requestPayload = JSON.stringify({ email });

    fetch(`${baseUrl}resend-otp`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: requestPayload,
    })
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);
        if (result.status) {
          setTimer(60); // Reset timer
          setCanResend(false);
        } else {
          setError(result.message);
        }
      })
      .catch(() => {
        setIsLoading(false);
        setError("An error occurred while resending OTP.");
      });
  };

  // Timer countdown logic
  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(interval); // Cleanup the interval
    } else {
      setCanResend(true);
      // Debugging to check the timer and page refresh
      console.log("Timer reached 0, refreshing the page...");
      setTimeout(() => {
        window.location.reload(); // This will refresh the page
      }, 0); // Refresh after 2 seconds
    }
  }, [timer]);

  return (
    <div className="w-full min-h-screen flex items-center justify-center bg-white">
      {/* OTP Section */}
      <div className="flex flex-col items-center justify-center w-full lg:w-1/2 px-6 sm:px-10 py-10 bg-white rounded-lg">
        {/* Logo */}
        <img className="h-14 md:h-20 md:hidden absolute top-5 left-0 m-4" src={logo} alt="Logo" />

        <br />
        <br />
        {/* Heading aligned to the left */}
        <h1 className="text-3xl font-bold text-left mb-6" style={{ color: secondaryColor }}>
          Verify OTP
        </h1>

        {/* OTP input fields centered */}
        <div className="flex flex-col gap-4 w-full max-w-[240px] sm:max-w-[300px]">
          <div className="flex gap-2 justify-center w-full">
            {otp.map((digit, index) => (
              <input
                key={index}
                id={`otp-input-${index}`}
                type="text"
                maxLength={1}
                value={digit}
                onChange={(e) => handleOtpChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                className={`w-8 sm:w-10 h-12 border text-center text-xl rounded-md focus:outline-none transition-all duration-300 ${
                  otpStatus === "success"
                    ? "border-green-500"
                    : otpStatus === "failure"
                    ? "border-red-500 animate-shake"
                    : "border-gray-300"
                }`}
                inputMode="numeric"
              />
            ))}
          </div>
        </div>

        {/* Error message */}
        {error && <p className="text-red-500 text-sm text-center">{error}</p>}

        {/* Resend OTP Button */}
        <div className="text-center mt-4">
          {canResend ? (
            <button
              onClick={resendOtp}
              className="text-blue-500 text-sm"
              disabled={isLoading}
            >
              
            </button>
          ) : (
            <p className="text-sm text-gray-500">
              You have left {timer}s.
            </p>
          )}
        </div>

        {/* Verify OTP Logic */}
        {otp.join("").length === otp.length && !isLoading && verifyOtp()}

        {/* Sign Up Link */}
        <div className="mt-6 text-sm text-center text-gray-500">
          Don't have an account?{" "}
          <NavLink to="/sign-up" className="text-[#2cb4d5]">
            Sign Up
          </NavLink>
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        {/* Footer */}
        <div className="w-full border-t border-gray-300 my-4"></div>
        <div className="text-xs text-center text-gray-500">
          <p>&copy; {new Date().getFullYear()} YehaCard. All rights reserved. Powered by PSI</p>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
