// import { useState } from "react";
// import { baseUrl, secondaryColor } from "../constants/constants";
// import { NavLink, useNavigate } from "react-router-dom";
// import ShowPasswordEye from "../components/Icons/ShowPasswordEye";
// import HidePasswordEye from "../components/Icons/HidePasswordEye";
// import { logo } from "../assets";
// import { MainButton } from "../components";
// import FormSideUi from "../components/formSideUi";

// const SignUP = () => {
//   const [firstName, setFirstName] = useState("");
//   const [firstNameError, setFirstNameError] = useState("");
//   const [lastName, setLastName] = useState("");
//   const [lastNameEror, setLastNameError] = useState("");
//   const [email, setEmail] = useState("");
//   const [emailError, setEmailError] = useState("");
//   const [phone, setPhone] = useState("");
//   const [phoneError, setPhoneError] = useState("");
//   const [password, setPassword] = useState("");
//   const [passwordError, setPasswordError] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [confirmPasswordError, setConfirmPasswordError] = useState("");
//   const [obscurePassword, setObscurePassword] = useState(true);
//   const [obscureConfirmPassword, setObscureConfirmPassword] = useState(true);
//   const [isLoading, setIsLoading] = useState(false);
//   const [errorMessage, setErrorMessage] = useState("");

//   const handleKeyDown = (event) => {
//     if (event.key === "Enter") {
//       onButtonClick();
//     }
//   };
//   const navigate = useNavigate();
//   const handlePasswordVisibility = () => {
//     setObscurePassword(!obscurePassword);
//   };
//   const handleConfirmPasswordVisibility = () => {
//     setObscureConfirmPassword(!obscureConfirmPassword);
//   };
//   function onButtonClick() {
//     const validateEmail = () => {
//       if (!email) {
//         setEmailError("Your email is required");
//         return false;
//       }
//       if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email.trim())) {
//         // if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
//         setEmailError("Please enter a valid email");
//         return false;
//       }

//       return true;
//     };

//     const validateField = (field, errorSetter, errorMessage) => {
//       if (!field) {
//         errorSetter(errorMessage);
//         return false;
//       }

//       return true;
//     };

//     setFirstNameError("");
//     setLastNameError("");
//     setEmailError("");
//     setPhoneError("");
//     setPasswordError("");
//     setConfirmPasswordError("");

//     if (
//       !validateField(firstName, setFirstNameError, "First name is required") ||
//       !validateField(lastName, setLastNameError, "Last Name is required") ||
//       !validateEmail() ||
//       !validateField(phone, setPhoneError, "Phone is required") ||
//       !validateField(password, setPasswordError, "Backup Password field is required!")
//     ) {
//       return;
//     }

//     if (password.length < 8) {
//       setPasswordError("The Backup Password must be 8 characters or longer");
//       return;
//     }

//     if (password !== confirmPassword) {
//       setConfirmPasswordError("Backup Passwords do not match");
//       return;
//     }
//     setIsLoading(true);

//     var myHeaders = new Headers();
//     myHeaders.append("Content-Type", "application/json");

//     var raw = JSON.stringify({
//       first_name: firstName.trim(),
//       last_name: lastName.trim(),
//       phone: phone.trim(),
//       email: email.trim(),
//       password: password.trim(),
//     });

//     var requestOptions = {
//       method: "POST",
//       headers: myHeaders,
//       body: raw,
//       redirect: "follow",
//     };

//     fetch(`${baseUrl}create-client`, requestOptions)
//       .then((response) => response.json())
//       .then((result) => {
//         setIsLoading(false);
//         // console.log(result.success);
//         if (result.status === true) {
//           localStorage.setItem("token", result.token);

//           // window.sessionStorage.setItem("token", result.token);
//           // console.log(result?.token, "usertoken 1");

//           navigate("/virtual-cards", {
//             state: { token: result.token },
//             replace: true,
//           });
//           // navigate("/Login", { replace: true });
//         }
//         if (result.success === false) {
//           setErrorMessage("Please use a different email or phone number.");
//         }
//         // console.log(result);
//       })
//       .catch((error) => {
//         // setIsLoading(false);
//         // console.log(error);
//         // let val = error.json();
//         // console.log("error", val);
//       });
//   }

//   return (
//     <>
//       <div className="w-full flex">
//         <FormSideUi />
//         <div className="w-full h-screen flex-col items-center justify-center">
//           <div
//             className={`min-h-screen flex flex-col items-center justify-center`}
//           >
//             <div className="flex  sm:px-20 py-2 items-center lg:hidden">
//             <img className="h-14 md:h-20 absolute top-5 left-0 m-4" src={logo} alt="Logo" />
//               {/* <div
//                 className={`text-2xl lg:text-4xl font-bold text-[${primaryColor}]`}
//               >
//                 YEHADIGITAL
//               </div> */}
//             </div>

//             <div className="w-full flex justify-center ">
//               <div className="w-[90%] md:w-[60%] bg-white   px-2  ">
//                 <h1
//                   style={{ color: secondaryColor }}
//                   className={`text-4xl font-bold text-[${secondaryColor}] text-center flex flex-col items-center gap-2 justify-center`}
//                 >
//                   Sign Up
//                 </h1>
//                 {/* <h1
//                   style={{ color: secondaryColor }}
//                   className={`text-md font-bold text-[${secondaryColor}] text-center flex flex-col items-center gap-2 justify-center py-2`}
//                 >
//                   Welcome to YEHADIGITAL
//                   <hr className="w-full md:w-[30%]" />
//                 </h1> */}

//                 <div className="py-10 flex w-full justify-center space-y-3 text-lg text-slate-600 ">
//                   <div className="flex justify-center flex-col items-center w-full ">
//                     <div className="flex gap-2 w-full">
//                       <div className="flex items-start flex-col w-full gap-1">
//                         <label>
//                           First Name <label className="text-red-500"> *</label>
//                         </label>
//                         <input
//                           value={firstName}
//                           placeholder="First Name"
//                           onKeyDown={handleKeyDown}
//                           onChange={(ev) => setFirstName(ev.target.value)}
//                           className={`border  ${
//                             firstNameError !== ""
//                               ? "border-red-500"
//                               : "border-slate-600/50"
//                           } rounded-md px-3 py-2 w-full`}
//                         />
//                         <label className="text-red-500 text-sm">
//                           {firstNameError}
//                         </label>
//                       </div>
//                       <div className="flex items-start flex-col w-full gap-1">
//                         <label>
//                           Last Name <label className="text-red-500"> *</label>
//                         </label>
//                         <input
//                           value={lastName}
//                           onKeyDown={handleKeyDown}
//                           placeholder="Last Name"
//                           onChange={(ev) => setLastName(ev.target.value)}
//                           className={`border ${
//                             lastNameEror !== ""
//                               ? "border-red-500"
//                               : "border-slate-600/50"
//                           } border-slate-600/50 rounded-md px-3 py-2 w-full`}
//                         />
//                         <label className="text-red-500 text-sm">
//                           {lastNameEror}
//                         </label>
//                       </div>
//                     </div>
//                     <div className="flex items-start flex-col w-full gap-1 py-1">
//                       <label>
//                         Email <label className="text-red-500"> *</label>
//                       </label>
//                       <input
//                         type="email"
//                         value={email}
//                         onKeyDown={handleKeyDown}
//                         placeholder="Email"
//                         onChange={(ev) => setEmail(ev.target.value)}
//                         className={`border ${
//                           emailError !== ""
//                             ? "border-red-500"
//                             : "border-slate-600/50"
//                         } border-slate-600/50 rounded-md px-3 py-2 w-full`}
//                       />
//                       <label className="text-red-500 text-sm">
//                         {emailError}
//                       </label>
//                     </div>
//                     <div className="flex items-start flex-col w-full gap-1 py-1">
//                       <label>
//                         Phone <label className="text-red-500"> *</label>
//                       </label>
//                       <input
//                         type="phone"
//                         value={phone}
//                         onKeyDown={handleKeyDown}
//                         placeholder="Phone Number"
//                         onChange={(ev) => setPhone(ev.target.value)}
//                         className={`border ${
//                           phoneError !== ""
//                             ? "border-red-500"
//                             : "border-slate-600/50"
//                         } border-slate-600/50 rounded-md px-3 py-2 w-full`}
//                       />
//                       <label className="text-red-500 text-sm">
//                         {phoneError}
//                       </label>
//                     </div>
//                     <div className="flex gap-2 w-full">
//                       <div className="flex items-start flex-col w-full gap-1">
//                         <label>
//                           Password <label className="text-red-500"> *</label>
//                         </label>
//                         <div
//                           className={`w-full flex border border-slate-600/50 rounded-md ${
//                             passwordError !== ""
//                               ? "border-red-500"
//                               : "border-slate-600/50"
//                           }`}
//                         >
//                           <input
//                             type={obscurePassword ? "password" : "text"}
//                             value={password}
//                             onKeyDown={handleKeyDown}
//                             placeholder="Backup"
//                             onChange={(ev) => setPassword(ev.target.value)}
//                             className={` outline-none   rounded-md px-3 py-2 w-full`}
//                           />
//                           <button
//                             className="mr-2"
//                             onClick={handlePasswordVisibility}
//                           >
//                             {obscurePassword ? (
//                               <ShowPasswordEye className={"h-6 w-6"} />
//                             ) : (
//                               <HidePasswordEye className={"h-6 w-6"} />
//                             )}
//                           </button>
//                         </div>

//                         <label className="text-red-500 text-sm">
//                           {passwordError}
//                         </label>
//                       </div>
//                       <div className="flex items-start flex-col w-full gap-1">
//                         <label>
//                           Confirm <label className="text-red-500"> *</label>
//                         </label>
//                         <div
//                           className={`w-full flex border border-slate-600/50 rounded-md ${
//                             confirmPasswordError !== ""
//                               ? "border-red-500"
//                               : "border-slate-600/50"
//                           }`}
//                         >
//                           <input
//                             type={obscureConfirmPassword ? "password" : "text"}
//                             onKeyDown={handleKeyDown}
//                             value={confirmPassword}m
//                             placeholder="Confirm Backup"
//                             onChange={(ev) =>
//                               setConfirmPassword(ev.target.value)
//                             }
//                             className={`outline-none rounded-md px-3 py-2 w-full`}
//                           />
//                           <button
//                             className="mr-2"
//                             onClick={handleConfirmPasswordVisibility}
//                           >
//                             {obscureConfirmPassword ? (
//                               <ShowPasswordEye className={"h-6 w-6"} />
//                             ) : (
//                               <HidePasswordEye className={"h-6 w-6"} />
//                             )}
//                           </button>
//                         </div>

//                         <label className="text-red-500 text-sm">
//                           {confirmPasswordError}
//                         </label>
//                       </div>
//                     </div>
//                     <br />
//                     {errorMessage && (
//                       <div className="text-center text-md text-red-500">
//                         {errorMessage}
//                       </div>
//                     )}

//                     <br />
//                     <MainButton
//                       label={"Signup"}
//                       onClick={onButtonClick}
//                       isLoading={isLoading}
//                     />

//                     <div className="flex justify-center gap-2 py-3 items-end">
//                       <div>Already have an account?</div>
//                       <NavLink
//                         //   onClick={handleMenuToggle}
//                         to="/Login"
//                         className={"text-[#2cb4d5]"}
//                       >
//                         Login
//                       </NavLink>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default SignUP;


export const VirtualCardLanding = () => {
  return (
    <div className="w-full h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-800">We'll be Back Soon!</h1>
        <p className="text-lg text-gray-600 mt-4">
          Our site is currently undergoing maintenance. We are working hard to improve your experience.
        </p>
        <p className="text-gray-500 mt-2">
          Please check back later. Thank you for your patience!
        </p>
      </div>
    </div>
  );
};

export default VirtualCardLanding; // Added default export
