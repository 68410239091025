// // import { useState } from "react";
// // import { baseUrl, primaryColor, secondaryColor } from "../constants/constants";
// // import { NavLink, useNavigate } from "react-router-dom";
// // import { MainButton } from "../components";
// // import HidePasswordEye from "../components/Icons/HidePasswordEye";
// // import ShowPasswordEye from "../components/Icons/ShowPasswordEye";
// // import { logo } from "../assets";
// // import FormSideUi from "../components/formSideUi";

// // const Login = () => {
// //   const [email, setEmail] = useState("");
// //   const [password, setPassword] = useState("");
// //   const [emailError, setEmailError] = useState("");
// //   const [passwordError, setPasswordError] = useState("");
// //   const [error, setError] = useState("");
// //   const [isLoading, setIsLoading] = useState(false);
// //   const [showPassword, setShowPassword] = useState(false);

// //   const navigate = useNavigate();

// //   const handleKeyDown = (event) => {
// //     if (event.key === "Enter") {
// //       onButtonClick();
// //     }
// //   };
// //   const handleToggleVisibility = () => {
// //     setShowPassword(!showPassword);
// //   };
// //   function onButtonClick() {
// //     setEmailError("");
// //     setPasswordError("");
// //     if (isLoading) return;

// //     if ("" === email && "" === password) {
// //       setEmailError("Your email is required");
// //       setPasswordError("Password field is required!");
// //       return;
// //     }
// //     if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email.trim())) {
// //       // if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
// //       setEmailError("Please enter a valid email");
// //       return;
// //     }

// //     if ("" === email) {
// //       setPasswordError("Your email is required");
// //       return;
// //     }

// //     if ("" === password) {
// //       setPasswordError("Password field is required!");
// //       return;
// //     }
// //     setIsLoading(true);
// //     var myHeaders = new Headers();
// //     myHeaders.append("Content-Type", "application/json");

// //     var raw = JSON.stringify({
// //       email: email.trim(),
// //       password: password,
// //     });

// //     var requestOptions = {
// //       method: "POST",
// //       headers: myHeaders,
// //       body: raw,
// //       //   redirect: "follow",
// //     };
// //     console.log(process.env.REACT_APP_BASE_URL);
// //     fetch(`${baseUrl}login`, requestOptions)
// //       .then((response) => {
// //         if (!response.ok) {
// //           setIsLoading(false);
// //         }
// //         return response.json();
// //       })
// //       .then((result) => {
// //         if (result.status !== true) {
// //           setError(result.message);
// //           return;
// //         }
// //         localStorage.setItem("token", result.token);
// //         // window.sessionStorage.setItem("token", result.token);
// //         // console.log(result?.token, "usertoken 1");

// //         navigate("/virtual-cards", {
// //           state: { token: result.token },
// //           replace: true,
// //         });

// //         window.location.reload();
// //       })
// //       .catch((error) => {
// //         setIsLoading(false);
// //         return;
// //         // console.log("error", error)
// //       });
// //   }
// //   return (
// //     <>
// //       <div className="w-full flex">
// //         <FormSideUi />

// //         <div className="w-full flex-col items-center ">
// //           <div
// //             className={`min-h-screen flex flex-col items-center justify-center`}
// //           >
// //             <div className="flex  sm:px-10 lg:hidden items-center">
// //               <img className="h-20  " src={logo} alt="" />
// //               <div
// //                 className={`text-xl md:text-4xl font-bold text-[${primaryColor}]`}
// //               >
// //                 {/* YEHADIGITAL */}
// //               </div>
// //             </div>
// //             <div className="w-full flex justify-center ">
// //               <div className="w-[90%] max-w-[600px] bg-white   px-5 sm:px-10 py-10">
// //                 <h1
// //                   style={{ color: secondaryColor }}
// //                   className={`text-4xl font-bold text-[${secondaryColor}] text-center flex flex-col items-center gap-2 justify-center`}
// //                 >
// //                   Login
// //                 </h1>
// //                 {/* <h1
// //                   style={{ color: secondaryColor }}
// //                   className={`text-md font-bold text-[${secondaryColor}] text-center flex flex-col items-center gap-2 justify-center py-2`}
// //                 >
// //                   Welcome to YEHADIGITAL
// //                   <hr className="w-full md:w-[30%]" />
// //                 </h1> */}

// //                 <div className="py-10 flex justify-center space-y-3 text-lg text-slate-600 ">
// //                   <div className="flex justify-center flex-col items-start w-full ">
// //                     <div className="flex items-start flex-col w-full gap-1">
// //                       <label>Email</label>
// //                       <input
// //                         value={email}
// //                         placeholder="Email Address"
// //                         onChange={(ev) => setEmail(ev.target.value)}
// //                         className="border border-slate-600/50 rounded-md px-3 py-2 w-full "
// //                         onKeyDown={handleKeyDown}
// //                       />
// //                       <label className="text-red-500 text-sm">
// //                         {emailError}
// //                       </label>
// //                     </div>
// //                     <br />
// //                     <div className="flex items-start flex-col w-full gap-1 ">
// //                       <div className="flex justify-between w-full items-center">
// //                         <label>Password</label>
// //                         <div className="text-[#2cb4d5]">
// //                           <NavLink
// //                             //   onClick={handleMenuToggle}
// //                             to="/forget-password"
// //                             className={({ isActive, isPending }) =>
// //                               isPending
// //                                 ? "pending"
// //                                 : isActive
// //                                 ? "text-[#2cb4d5]"
// //                                 : ""
// //                             }
// //                           >
// //                             Forgot?
// //                           </NavLink>
// //                         </div>
// //                       </div>

// //                       <div className="flex w-full border border-slate-600/50 rounded-md pr-2">
// //                         <input
// //                           value={password}
// //                           placeholder="Password"
// //                           onChange={(ev) => setPassword(ev.target.value)}
// //                           className=" px-3 py-2 w-full border-none outline-none rounded-md "
// //                           type={showPassword ? "text" : "password"}
// //                           onKeyDown={handleKeyDown}
// //                         />
// //                         <button onClick={handleToggleVisibility}>
// //                           {showPassword ? (
// //                             <HidePasswordEye className={"h-6 w-6"} />
// //                           ) : (
// //                             <ShowPasswordEye className={"h-6 w-6"} />
// //                           )}
// //                         </button>
// //                       </div>

// //                       <label className="text-red-500 text-sm">
// //                         {passwordError}
// //                       </label>
// //                     </div>
// //                     <label className="text-red-500 text-sm text-center">
// //                       {error}
// //                     </label>

// //                     <br />
// //                     <MainButton
// //                       label={"Login"}
// //                       onClick={onButtonClick}
// //                       isLoading={isLoading}
// //                     />

// //                     <div className="flex justify-center w-full mt-2 gap-3">
// //                       Don't have an account?
// //                       <NavLink to={"/sign-up"} className={"text-[#2cb4d5]"}>
// //                         SignUp
// //                       </NavLink>
// //                     </div>
// //                   </div>
// //                 </div>
// //               </div>
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //     </>
// //   );
// // };

// // export default Login;


// import { useState } from "react";
// import { logo } from "../assets";
// import { baseUrl, primaryColor, secondaryColor } from "../constants/constants";
// import { MainButton } from "../components";
// import { NavLink } from "react-router-dom";
// import VerifyOtp from "./VerifyOtp";
// import FormSideUi from "../components/formSideUi";


// const ForgetPassword = () => {
//   const [email, setEmail] = useState("");
//   const [emailError, setEmailError] = useState("");
//   const [error, setError] = useState("");
//   const [isLoading, setIsLoading] = useState(false);
//   const [step, setStep] = useState(0);

//   const onButtonClick = () => {
//     if (isLoading) return; // Prevent further clicks while loading

//     setIsLoading(true); // Set loading to true when button is clicked
//     setEmailError(""); // Reset email error
//     setError(""); // Reset general error

//     // Validation for email field
//     if ("" === email) {
//       setEmailError("The email field is required");
//       setIsLoading(false); // Reset loading state after validation failure
//       return;
//     }
    
//     if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
//       setEmailError("Please enter a valid email");
//       setIsLoading(false); // Reset loading state after validation failure
//       return;
//     }

//     // Make the API request to send OTP
//     var myHeaders = new Headers();
//     myHeaders.append("Content-Type", "application/json");

//     var raw = JSON.stringify({
//       email: email,
//     });

//     var requestOptions = {
//       method: "POST",
//       headers: myHeaders,
//       body: raw,
//       redirect: "follow",
//     };

//     // API call to send OTP
//     fetch(`${baseUrl}send-otp`, requestOptions)
//       .then((response) => {
//         if (!response.ok) {
//           setIsLoading(false); // Reset loading state on failure
//         }
//         return response.json();
//       })
//       .then((result) => {
//         if (result.status !== true) {
//           setError(result.message);
//           setIsLoading(false); // Reset loading state if error occurs
//           return;
//         }
//         localStorage.setItem("email", email); // Save email to localStorage
//         setStep(1); // Move to the next step (OTP verification)
//         setIsLoading(false); // Reset loading state after successful request
//       })
//       .catch((error) => {
//         setIsLoading(false); // Reset loading state on network error
//         setError("An error occurred. Please try again later.");
//       });
//   };

//   return (
//     <>
//       <div className="w-full flex">
//         <FormSideUi />
//         {step === 0 ? (
//           <div className="w-full flex-col items-center justify-center">
//             <div className={`min-h-screen  flex flex-col items-center justify-center`}>
//               <div className="flex sm:px-10 py-2 items-center lg:hidden">
           
//   <img className="h-14 md:h-20 absolute top-5 left-0 m-4" src={logo} alt="Logo" />

//   <br></br>
//   <br></br>
//                           <br></br>
//                 <div className={`text-2xl  md:text-4xl font-bold text-[${primaryColor}]`}>
//                   {/* YEHADIGITAL */}
//                 </div>
//               </div>
//               <div className="w-full flex  justify-center py-5">
//                 <div className="w-[90%] max-w-[600px] bg-white px-5 sm:px-10 py-5">
//                   <h1
//                     style={{ color: secondaryColor }}
//                     className={`text-2xl lg:text-4xl font-bold text-[${secondaryColor}] `}
//                   >
//                     Login with Email
//                   </h1>
//                   <h1
//                     style={{ color: secondaryColor }}
//                     className={`text-md text-[${secondaryColor}] py-1`}
//                   >
                
//                     Please enter your email address
//                   </h1>

//                   <div className=" flex justify-center space-y-3 text-lg text-slate-600 ">
//                     <div className="flex justify-center flex-col items-start w-full gap-2">
//                       <div className="flex items-start flex-col w-full gap-0">
//                         <input
//                           value={email}
//                           placeholder="Enter your email"
//                           onChange={(ev) => setEmail(ev.target.value)}
//                           className="border border-slate-600/50 rounded-md px-3 py-2 w-full"
//                         />
//                         <label className="text-red-500 text-sm">
//                           {emailError}
//                         </label>
//                       </div>

//                       <label className="text-red-500 text-sm text-center">{error}</label>

//                       {/* Terms and Conditions Checkbox (Always checked) */}
//                       <div className="flex items-center">
//                         <input
//                           type="checkbox"
//                           id="agree-terms"
//                           checked={true} // Always checked
//                           disabled={false} // Disabled so the user cannot uncheck
//                         />
//                         <label htmlFor="agree-terms" className="ml-2 text-sm text-slate-600">
//                           I agree to the Terms and Conditions.
//                         </label>
//                       </div>

//                       {/* MainButton will be enabled as the checkbox is always checked */}
//                       <MainButton
//                         label={"Continue"}
//                         onClick={onButtonClick}
//                         isLoading={isLoading}
//                       />
                      
                      
                     

//                       <div className="flex flex-col items-center w-full mt-2 gap-3">
//                           <div className="flex items-center gap-3">
//                             <span>Don't have an account? </span>
//                             <NavLink to="/sign-up" className="text-[#2cb4d5]">
//                               SignUp
//                             </NavLink>
//                           </div>
//                           <br></br>
//                           <br></br>
//                           <br></br>
//                           <br></br>
//                           <br></br>
//                           <br></br>
//                           <br></br>
                          
                          
//                           {/* <img className="h-15 md:h-20 absolute top-0 left-8 m-4" src="/visa.gif" alt="Logo" /> */}
//                           {/* Footer */}
//         <div className="w-full border-t border-gray-300 my-4"></div>
//         <div className="text-xs text-center text-gray-500">
//           <p>&copy; {new Date().getFullYear()} YehaCard. All rights reserved. Powered by PSI</p>
                        
//                       </div>
//                     </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         ) : step === 1 ? (
//           <VerifyOtp />
//         ) : (
//           <></>
//         )}
//       </div>
//     </>
//   );
// };

// export default ForgetPassword;




export const VirtualCardLanding = () => {
  return (
    <div className="w-full h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-800">We'll be Back Soon!</h1>
        <p className="text-lg text-gray-600 mt-4">
          Our site is currently undergoing maintenance. We are working hard to improve your experience.
        </p>
        <p className="text-gray-500 mt-2">
          Please check back later. Thank you for your patience!
        </p>
      </div>
    </div>
  );
};

export default VirtualCardLanding; // Added default export
